import React from 'react';
import BuilderTextInput from '../../../../containers/Builder/TextInput';

const UIFormFieldset = () => (
  <fieldset>
    <h3>UI Setup</h3>
    <BuilderTextInput name="title" placeholder="Enter a title for your notification" />
    <BuilderTextInput name="body" placeholder="Enter a short message" />
    <BuilderTextInput name="iconUrl" placeholder="Enter an image link for your icon" />
  </fieldset>
);

export default UIFormFieldset;
