export const NEW_IID = 'NEW_IID';
export const BROWSER_SUPPORT = 'BROWSER_SUPPORT_ACTION';

export type IID = string;

export interface NewIIDAction {
  type: typeof NEW_IID;
  iid: IID;
}

export const newIIDAction = (iid: IID | null) => ({
  type: NEW_IID,
  iid,
});

export type WebPushClientAction = NewIIDAction;
