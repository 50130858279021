import React from 'react';

interface OwnProps {
  hasFormUpdated: boolean;
}

type Props = OwnProps;

const SaveButton = ({ hasFormUpdated }: Props) => {
  return (
    <button type="submit" className="button save-button" disabled={!hasFormUpdated}>
      Save
    </button>
  );
};

export default SaveButton;
