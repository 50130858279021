import { notMissing, isMissing, Dictionary } from '@yieldify/gendry-dragonglass';
import { config } from '../config';
import { ClientSettings } from '../containers/Builder';
import { store } from '../store/configureStore';
import { loginExpiredAction, loginFailureAction } from '../actions/users';
import CustomError, { CustomErrorTypes, HttpResponseCodes } from './custom-error';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';

export type RequestMethodType = 'GET' | 'POST' | 'DELETE' | 'PUT';

export interface RequestHeaders {
  [header: string]: string;
  accept: string;
  'content-type': string;
}

export interface RequestBase {
  headers?: RequestHeaders;
  method: RequestMethodType;
  body?: string;
}

export interface ApiRequest extends RequestBase {
  url: string;
}

export const createJsonOptions = (
  method: RequestMethodType,
  payload?: Dictionary<string | number | boolean | null>,
): RequestBase => {
  const headers = {
    accept: 'application/json, text/plain, */*',
    'content-type': 'application/json',
  };
  const body = notMissing(payload) ? JSON.stringify(payload) : undefined;
  return {
    headers,
    method,
    body,
  };
};

export async function checkStatus(response: Response): Promise<Response> {
  const { status } = response;
  switch (status) {
    case HttpResponseCodes.OK:
    case HttpResponseCodes.CREATED:
    case HttpResponseCodes.NO_CONTENT:
      return response;
    case HttpResponseCodes.BAD_REQUEST:
      throw new Error(await response.text());
    case HttpResponseCodes.UNAUTHORISED:
      throw new CustomError(CustomErrorTypes.UNAUTHORISED);
    case HttpResponseCodes.NOT_FOUND:
      throw new CustomError(CustomErrorTypes.ROUTE_NOT_FOUND);
    default:
      throw new CustomError(CustomErrorTypes.DEFAULT_ERROR);
  }
}

export function callJaime(
  options: ApiRequest,
  pendingAction: Function,
  successAction: Function,
  failureAction: Function,
  handleResponse: Function,
  // tslint:disable-next-line:no-any
): ThunkDispatch<void, AppState, Action<any>> {
  return async (dispatch: Function) => {
    dispatch(pendingAction());
    const userProfile = store.getState().session.userProfile;
    const token: string | undefined =
      notMissing(userProfile) && notMissing(userProfile.token) ? userProfile.token : undefined;
    if (isMissing(token)) {
      throw new CustomError(CustomErrorTypes.UNAUTHORISED);
    }

    const authenticatedConfig = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(options.url, authenticatedConfig)
      .then(checkStatus)
      .then((res) => handleResponse(res, authenticatedConfig))
      .then((json) => dispatch(successAction(json)))
      .catch((error: CustomError) => {
        if (notMissing(error.message)) {
          if (error.message === CustomErrorTypes.UNAUTHORISED) {
            dispatch(loginExpiredAction());
            dispatch(loginFailureAction(error.message));
          }
          dispatch(failureAction(error.message));
        }
      });
  };
}

export function getClientDetails(websiteId: number): ClientSettings | undefined {
  return config['client-whitelist'].find(
    (client: ClientSettings) => client.websiteId === websiteId,
  );
}
