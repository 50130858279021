import React, { useRef } from 'react';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { makeStyles } from '@material-ui/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

const copyRefContent = (ref: React.RefObject<HTMLInputElement>) => {
  if (notMissing(ref.current)) {
    ref.current.style.display = 'block';
    ref.current.select();
    document.execCommand('copy');
    ref.current.style.display = 'none';
  }
};

interface Props {
  text: string;
  className?: string;
}

const useStyles = makeStyles(() => ({
  copyInput: {
    display: 'none',
  },
}));

const CopyButton = ({ text, className }: Props) => {
  const inputCopy = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  return (
    <IconButton
      color="default"
      onClick={() => copyRefContent(inputCopy)}
      title="Copy"
      className={className}
    >
      <input ref={inputCopy} className={classes.copyInput} type="text" defaultValue={text} />
      <FileCopy />
    </IconButton>
  );
};

export default CopyButton;
