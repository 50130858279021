import { AppState } from '../reducers/index';
import { Store } from 'redux';
import { deepEqual, isMissing } from '@yieldify/gendry-dragonglass';

export const STORAGE_KEY_USER_PROFILE = 'user_profile';

export const STORAGE_KEY_BUILDER_SETTINGS = 'builder_settings';

export function localStoragePersist<T extends Object>(key: string, toPersist: T): void {
  return window.localStorage.setItem(key, JSON.stringify(toPersist));
}

export function getPersistedStateFromStorage<T>(key: string): T | undefined {
  const item = localStorage.getItem(key);
  if (isMissing(item)) {
    return undefined;
  }
  try {
    return JSON.parse(item) as T;
  } catch (e) {
    return undefined;
  }
}

export function connectPersistence<T>(
  store: Store<AppState>,
  getStateToPersist: (state: AppState) => T | undefined,
  persistKey: string,
  persistFn: (key: string, data: T) => void,
): void {
  let previousData: T | null | undefined;

  store.subscribe(() => {
    const newValue = getStateToPersist(store.getState());
    if (
      newValue !== null &&
      newValue !== undefined &&
      previousData !== newValue &&
      !deepEqual(previousData, newValue)
    ) {
      persistFn(persistKey, newValue);
      previousData = newValue;
    }
  });
}
