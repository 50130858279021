import React from 'react';

interface OwnProps {
  disabled?: boolean;
  clickHandler: Function;
}

type Props = OwnProps;

const ReadyToSendButton = ({ disabled = false, clickHandler }: Props) => {
  return (
    <button
      type="submit"
      className="button ready-to-send-button"
      onClick={() => clickHandler()}
      disabled={disabled}
    >
      Ready to send?
    </button>
  );
};

export default ReadyToSendButton;
