import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { notMissing, isMissing } from '@yieldify/gendry-dragonglass';
import { makeStyles } from '@material-ui/styles';
import { MessagingClient } from '../util/firebase';
import CopyButton from './CopyButton';
import Tooltip from '@material-ui/core/Tooltip';

let messagingSwRegistration: ServiceWorkerRegistration | null = null;

interface Props {
  firebaseMessagingSdk: MessagingClient;
  onNewToken?: Function;
  serviceWorkerPath: string;
  // tslint:disable:no-any
  ShowIIDComponent?: React.ComponentType<any>;
  PermissionErrorComponent?: React.ComponentType<any>;
  GenerateComponent?: React.ComponentType<any>;
  // tslint:enable:no-any
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  iidText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const PermissionDenied = () => <span>Preview Permissions Denied</span>;

const FirebaseIID = ({
  firebaseMessagingSdk,
  serviceWorkerPath,
  onNewToken,
  ShowIIDComponent = DefaultCopyableIID,
  PermissionErrorComponent = PermissionDenied,
  GenerateComponent = DefaultGenerateIID,
}: Props) => {
  const [permission, setPermission] = useState(Notification.permission);
  const [iid, setIid] = useState<null | string>(null);
  const [swRegPending, setPendingReg] = useState(false);

  const getToken = async () => {
    try {
      if (isMissing(messagingSwRegistration)) {
        // Register the service worker and keep the reference of the registration so we don't register it again
        // Registering it several times per page load will throw an error
        messagingSwRegistration = await getSwRegistration(firebaseMessagingSdk, serviceWorkerPath);
      }

      // Retrieve the token
      const token = await firebaseMessagingSdk.getToken();
      setIid(token);

      if (notMissing(onNewToken)) {
        onNewToken(token);
      }
    } finally {
      setPendingReg(false);
      setPermission(Notification.permission);
    }
  };

  // If the permission has already been granted and the registration hasn't been started already
  // We can register the service worker and get the token
  if (permission === 'granted' && isMissing(iid) && !swRegPending) {
    // tslint:disable-next-line: no-floating-promises
    getToken();
    setPendingReg(true);
  }

  return permission === 'granted' && notMissing(iid) ? (
    // If we got an IID, we show it
    <ShowIIDComponent IID={iid} />
  ) : permission === 'default' ? (
    // otherwise if the permission for push notification is in ask, we let the user enable it
    <GenerateComponent onClick={getToken} />
  ) : permission === 'denied' ? (
    // if permissions are denied then we show a denied error
    <PermissionErrorComponent />
  ) : (
    // For all other states we show a loading icons (assuming we're waiting for promises to resolve)
    <CircularProgress />
  );
};

export interface ShowIIDComponentProps {
  IID: string;
}

export interface GenerateIIDComponentProps {
  onClick: Function;
}

export const DefaultGenerateIID = ({ onClick }: GenerateIIDComponentProps) => (
  <Button variant="contained" color="default" onClick={() => onClick()}>
    Get Device IID
  </Button>
);

export const DefaultCopyableIID = ({ IID }: ShowIIDComponentProps) => {
  const classes = useStyles();
  return (
    <Tooltip title="Your device's preview token" placement="bottom-start">
      <div className={classes.wrapper}>
        <CopyButton text={IID} />
        IID:
        <span className={classes.iidText}>{IID}</span>
      </div>
    </Tooltip>
  );
};

export const getSwRegistration = async (
  messaging: MessagingClient,
  swPath: string,
): Promise<ServiceWorkerRegistration> => {
  // Request notification permission (it only prompts if it hasn't already been granted)
  await messaging.requestPermission();

  const reg = await navigator.serviceWorker.register(swPath, {
    updateViaCache: 'imports',
  });

  messaging.useServiceWorker(reg);

  messaging.onMessage((payload) =>
    // tslint:disable-next-line:no-console
    console.log({ payload }, `Web Push Service worker - Message received`),
  );

  return reg;
};

export default FirebaseIID;
