import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';

interface OwnProps {
  disabled?: boolean;
  clickHandler: Function;
  className?: string;
}

const DuplicateCampaign = ({ className, clickHandler }: OwnProps) => {
  return (
    <IconButton
      color="default"
      onClick={(e) => {
        e.preventDefault();
        clickHandler();
      }}
      title="Duplicate"
      className={className}
    >
      <FileCopy />
    </IconButton>
  );
};

export default DuplicateCampaign;
