import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  NotificationsAction,
} from '../actions/notifications';
import { DELETE_CAMPAIGN_SUCCESS, DeleteCampaignAction } from '../actions/deleteCampaign';

export interface APINotification {
  silent: boolean;
  id: number;
  name: string;
  websiteId: number;
  topic: string;
  title: string;
  body: string;
  iconUrl: string;
  linkUrl: string;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
  sentAt: string | null;
  version: number;
  ycpCampaignId: number | null;
}

export interface PushNotification extends APINotification {
  clientName: string;
  domain: string;
}

export interface NotificationsState {
  notificationsPending: boolean;
  items: PushNotification[];
  notificationsError: string | null;
}

const initialState: NotificationsState = {
  items: [],
  notificationsPending: false,
  notificationsError: null,
};

export function notifications(
  state = initialState,
  action: NotificationsAction | DeleteCampaignAction,
): NotificationsState {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsError: null,
        notificationsPending: true,
      };

    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsPending: false,
        notificationsError: null,
        items: action.items,
      };

    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };

    case NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsPending: false,
        notificationsError: action.error,
      };
    default:
      return state;
  }
}
