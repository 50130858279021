import React from 'react';
import BuilderTextInput from '../../containers/Builder/TextInput';

interface StateProps {
  status: 'draft' | 'sent';
}

type Props = StateProps;

const BuilderSubheading: React.FC<Props> = ({ status }: Props) => {
  return (
    <div>
      <h3 className="subheading">
        <span className={`status-${status}`}>{status}</span> /{' '}
        <BuilderTextInput name="name" placeholder="Your campaign name" />
      </h3>

      <div className="yellow-underline" />
    </div>
  );
};

export default BuilderSubheading;
