import { session } from './session';
import { notifications } from './notifications';
import { combineReducers } from 'redux';
import { campaignBuilder } from './campaignBuilder';
import { createdCampaign } from './createCampaign';
import { deleteCampaign } from './deleteCampaign';

const rootReducer = combineReducers({
  session,
  campaignBuilder,
  notifications,
  createdCampaign,
  deleteCampaign,
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>;
