import React from 'react';
import BuilderTextInput from '../../../../containers/Builder/TextInput';
import RedirectLinkInput from './RedirectLinks';
import LinkIcon from '@material-ui/icons/Link';
import { Link } from '@material-ui/core';
import { ClientSettings } from '../../../../containers/Builder';
import { notMissing } from '@yieldify/gendry-dragonglass';

interface FormProps {
  selectedClient: ClientSettings | undefined;
  ycpCampaignId: number | null;
}

const AnalyticsAndReportingFieldset: React.FC<FormProps> = ({
  selectedClient,
  ycpCampaignId,
}: FormProps) => {
  const websiteId = notMissing(selectedClient) ? String(selectedClient.websiteId) : undefined;
  const organisationId = notMissing(selectedClient)
    ? String(selectedClient.organisationId)
    : undefined;
  const ycpCampaignUrl =
    notMissing(organisationId) && notMissing(websiteId) && notMissing(ycpCampaignId)
      ? `https://convert.yieldify.com/org/${organisationId}/site/${websiteId}/dashboard/${ycpCampaignId}/performance`
      : undefined;
  return (
    <fieldset>
      <h3>Topic</h3>
      <BuilderTextInput name="topic" placeholder="Enter the topic" />
      <div className="metrics">
        <h3>Metrics</h3>
        <BuilderTextInput
          name="ycpCampaignId"
          placeholder="YCP campaign ID used for metric tracking"
          required={false}
          type="number"
        />
        {notMissing(ycpCampaignUrl) && (
          <span className="web-icon">
            <Link href={ycpCampaignUrl} target="_blank" rel="noopener">
              <LinkIcon />
            </Link>
          </span>
        )}
      </div>
      <h3>Re-direct Link &amp; Reporting</h3>
      <RedirectLinkInput />
    </fieldset>
  );
};

export default AnalyticsAndReportingFieldset;
