import React from 'react';
import ChromeIcon from '../assets/chrome_icon.png';
import YieldifyIcon from '../assets/yieldify_icon.png';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { getClientDetails } from '../util/api-utils';
import { CampaignBuilderForm } from '../reducers/campaignBuilder';

export interface NotificationProps {
  notificationData: CampaignBuilderForm;
}

const NotificationPreview: React.FC<NotificationProps> = ({
  notificationData,
}: NotificationProps) => {
  const { title, iconUrl, body, websiteId } = notificationData;
  const client = getClientDetails(websiteId);
  let domain = '';
  if (notMissing(client)) {
    domain = client.domain;
  }
  return (
    <div className="browser-notification">
      <div className="browser-notification-icon">
        <img src={ChromeIcon} alt="Chrome Icon" />
      </div>
      <div className="browser-notification-details">
        <h6 className="browser-notification-title">{title}</h6>
        <h6>{domain}</h6>
        <h6 className="regular-font-weight">{body}</h6>
      </div>
      <div className="browser-notification-brand-icon">
        <img
          src={notMissing(iconUrl) && iconUrl !== '' ? iconUrl : YieldifyIcon}
          alt="Brand Icon"
        />
      </div>
    </div>
  );
};

export default NotificationPreview;
