import React from 'react';

interface OwnProps {
  disabled?: boolean;
  clickHandler: Function;
}

type Props = OwnProps;

const SendButton = ({ disabled = false, clickHandler }: Props) => {
  return (
    <button
      type="submit"
      className="button send-button"
      onClick={() => clickHandler()}
      disabled={disabled}
    >
      Yes - send it now!
    </button>
  );
};

export default SendButton;
