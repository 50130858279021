import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import React, { Component } from 'react';
import BuilderSubheading from '../../components/Builder/Subheading';
import ConfirmationModal from '../../components/Builder/ConfirmationModal';
import BuilderForm from '../../components/Builder/Form';
import { CampaignBuilderForm, extractFormData } from '../../reducers/campaignBuilder';
import BuilderPreview from '../../components/Builder/Preview';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import {
  builderGetNotificationThunkAction,
  CampaignBuilderThunkAction,
  builderSaveNotificationThunkAction,
  builderSendNotificationThunkAction,
  CampaignBuilderShowErrorModalAction,
  builderShowErrorModalAction,
} from '../../actions/campaignBuilder';
import PreviewButton from '../../components/Builder/Buttons/Preview';
import ReadyToSendButton from '../../components/Builder/Buttons/ReadyToSend';
import { deepEqual, notMissing } from '@yieldify/gendry-dragonglass';
import { config } from '../../config';
import { APINotification } from '../../reducers/notifications';

export interface ClientSettings {
  organisationId: number;
  websiteId: number;
  domain: string;
  name: string;
}

interface OwnProps {
  notificationId: string;
}

interface StateProps {
  campaignDetails: {
    id: string;
    sent: boolean;
    formData: CampaignBuilderForm;
    version: number;
  };
  errorMessage: string | null;
  formUpdated: boolean;
  showErrorModal: boolean;
  showPreviewButton: boolean;
  showSendButton: boolean;
}

interface DispatchProps {
  displayErrorModal: (showModal: boolean) => CampaignBuilderShowErrorModalAction;
  getNotificationData: (id: string) => CampaignBuilderThunkAction;
  saveNotification: (
    id: string,
    version: number,
    newFormData: CampaignBuilderForm,
  ) => CampaignBuilderThunkAction;
  sendNotification: (id: string, version: number) => CampaignBuilderThunkAction;
}

type Props = RouteComponentProps<OwnProps> & StateProps & DispatchProps;

interface BuilderState {
  campaignDetails: APINotification;
  readyToPreview: boolean;
  readyToSend: boolean;
}

interface OwnState {
  confirmationModalIsVisible: boolean;
}

type State = Partial<BuilderState> & OwnState;

class Builder extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      confirmationModalIsVisible: false,
      readyToPreview: false,
    };
  }

  public componentDidMount(): void {
    this.props.getNotificationData(this.props.campaignDetails.id);
  }

  public render(): JSX.Element {
    const {
      campaignDetails,
      displayErrorModal,
      errorMessage,
      formUpdated,
      saveNotification,
      sendNotification,
      showErrorModal,
      showPreviewButton,
      showSendButton,
    } = this.props;
    const { sent, id, formData, version } = campaignDetails;
    return (
      <main>
        <h1>Builder</h1>
        <BuilderSubheading status={sent ? 'sent' : 'draft'} />
        <BuilderForm
          clientList={config['client-whitelist']}
          hasFormUpdated={formUpdated}
          saveButtonFn={() => saveNotification(id, version, formData)}
          selectedClientId={formData.websiteId}
          showButton={!sent}
          formData={formData}
        />
        <BuilderPreview formData={formData} />
        <div className="cta-button-container">
          {showPreviewButton && (
            <PreviewButton disabled={formUpdated} notificationId={id} version={version} />
          )}
          {sent
            ? false
            : showSendButton && (
                <ReadyToSendButton
                  clickHandler={() => this.setState({ confirmationModalIsVisible: true })}
                />
              )}
        </div>
        <ConfirmationModal
          onClose={() => this.setState({ confirmationModalIsVisible: false })}
          isVisible={this.state.confirmationModalIsVisible}
          sendNotification={() => sendNotification(id, version)}
        />
        <Dialog open={showErrorModal}>
          <DialogTitle>We've encountered a problem!</DialogTitle>
          <DialogContent>{errorMessage}</DialogContent>
          <DialogActions>
            <Button onClick={() => displayErrorModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}

const getYcpCampaignId = (id: number | null): number | null =>
  notMissing(id) && String(id) !== '' ? Number(id) : null;

const mapStateToProps = (
  { campaignBuilder }: AppState,
  ownProps: RouteComponentProps<OwnProps>,
): StateProps => {
  const { showErrorModal, errorMessage } = campaignBuilder.errorHandling;
  const { campaignDetails } = campaignBuilder;
  const formData: CampaignBuilderForm = {
    ...campaignBuilder.formData,
    ycpCampaignId: getYcpCampaignId(campaignBuilder.formData.ycpCampaignId),
  };
  const { sentAt, version } = campaignDetails;
  const savedFormData = extractFormData(campaignDetails);
  const formDataHasChanged = deepEqual(savedFormData, formData);
  return {
    campaignDetails: {
      id: ownProps.match.params.notificationId,
      sent: notMissing(sentAt),
      version,
      formData,
    },
    errorMessage,
    formUpdated: !formDataHasChanged,
    showErrorModal,
    showPreviewButton: formDataHasChanged,
    showSendButton: formDataHasChanged,
  };
};

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  getNotificationData: (id: string) => dispatch(builderGetNotificationThunkAction(id)),
  saveNotification: (id: string, version: number, newFormData: CampaignBuilderForm) =>
    dispatch(builderSaveNotificationThunkAction(id, version, newFormData)),
  sendNotification: (id: string, version: number) =>
    dispatch(builderSendNotificationThunkAction(id, version)),
  displayErrorModal: (showModal: boolean) => dispatch(builderShowErrorModalAction(showModal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Builder);
