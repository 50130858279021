import React, { useState } from 'react';
import { ClickAwayListener, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClientSettings } from '.';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import {
  CampaignBuilderClientSelectionAction,
  builderClientSelectionAction,
} from '../../actions/campaignBuilder';
import { Dispatch } from 'redux';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

interface OwnProps {
  clientList: ClientSettings[];
  selectedClient: ClientSettings | undefined;
}

interface StateProps {
  sent: boolean;
}

interface DispatchProps {
  dispatchClientSelectionAction: (
    selectedWebsiteId: number,
  ) => CampaignBuilderClientSelectionAction;
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme) => ({
  MuiButton: {
    color: '#ffc200 !important',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: 0,
    textAlign: 'left',
    verticalAlign: 'inherit',
  },
  MuiPaper: {
    position: 'absolute',
    opacity: 0.9,
    top: calculateTopOffset(),
    right: 0,
    left: 'calc((12.5% * 2) + 20px)',
    maxWidth: '300px',
    // tslint:disable-next-line:no-magic-numbers
    [theme.breakpoints.down(1700)]: {
      left: 'calc((12.5% * 1.5) + 20px)',
    },
    // tslint:disable-next-line:no-magic-numbers
    [theme.breakpoints.down(1450)]: {
      left: 'calc(12.5% + 20px)',
    },
    // tslint:disable-next-line:no-magic-numbers
    [theme.breakpoints.down(1200)]: {
      left: 'calc(8% + 20px)',
    },
  },
  clientHeading: {
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}));

const BuilderClientDropdown: React.FC<Props> = ({
  clientList,
  dispatchClientSelectionAction,
  selectedClient,
  sent,
}: Props) => {
  const [isOpen, toggleDropdown] = useState(false);
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={() => toggleDropdown(!isOpen ? false : !isOpen)}>
      <div>
        <span className={classes.clientHeading}>Client: &nbsp;&nbsp;</span>
        <Button
          className={classes.MuiButton}
          onClick={() => (sent ? toggleDropdown(false) : toggleDropdown(!isOpen))}
          disabled={sent}
        >
          {notMissing(selectedClient) ? selectedClient.name : 'Unknown Client'}
          {!sent && <KeyboardArrowDown />}
        </Button>
        {isOpen && notMissing(selectedClient)
          ? generateDropdownList(
              classes.MuiPaper,
              clientList,
              selectedClient.name,
              dispatchClientSelectionAction,
              toggleDropdown,
            )
          : null}
      </div>
    </ClickAwayListener>
  );
};

const generateDropdownList = (
  className: string,
  clientList: ClientSettings[],
  currentClient: string,
  dispatchClientSelectionAction: Function,
  toggleDropdownFn: Function,
) => {
  const topStyleOverride = calculateTopOffset();
  const dropdownList = Object.keys(clientList).map((key: string) => {
    const clientName = clientList[Number(key)].name;
    const clientId = clientList[Number(key)].websiteId;
    if (clientName !== currentClient) {
      return (
        <Button
          key={clientId}
          value={clientId}
          onClick={() => {
            dispatchClientSelectionAction(clientId);
            toggleDropdownFn(false);
          }}
        >
          {clientName}
        </Button>
      );
    }
  });
  return (
    <Paper className={className} style={{ top: topStyleOverride }}>
      {dropdownList}
    </Paper>
  );
};

const calculateTopOffset = (): number => {
  const element = document.querySelector('.push-notification-form .MuiButton-label');
  if (notMissing(element)) {
    const { top, height } = element.getBoundingClientRect();
    const additionalOffset = 10;
    return top + window.scrollY + height + additionalOffset;
  }
  return 0;
};

const mapStateToProps = (state: AppState): StateProps => ({
  sent: notMissing(state.campaignBuilder.campaignDetails.sentAt),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchClientSelectionAction: (selectedWebsiteId: number) =>
    dispatch(builderClientSelectionAction(selectedWebsiteId)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(BuilderClientDropdown);
