import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface OwnProps {
  clickHandler: Function;
  className?: string;
}

const useStyles = makeStyles(() => ({
  MuiFab: {
    backgroundColor: '#FFC200',
    '&:hover': {
      backgroundColor: '#FFD540',
    },
  },
}));

const CreateCampaignButton = ({ clickHandler, className }: OwnProps) => {
  {
    const classes = useStyles();
    return (
      <Fab
        aria-label="add"
        onClick={() => clickHandler()}
        className={`${classes.MuiFab} ${className}`}
      >
        <AddIcon />
      </Fab>
    );
  }
};

export default CreateCampaignButton;
