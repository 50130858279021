import { UserProfile } from '../reducers/session';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_EXPIRED = 'LOGIN_EXPIRED';

export type LoginAction =
  | LoginSuccessAction
  | LoginFailureAction
  | LoginPendingAction
  | LoginExpiredAction;

interface LoginPendingAction {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  isLoginSuccess: boolean;
  userProfile: UserProfile;
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  loginError: string | undefined;
}

export interface LoginExpiredAction {
  type: typeof LOGIN_EXPIRED;
}

export function loginSuccessAction(userProfile: UserProfile): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    isLoginSuccess: true,
    userProfile,
  };
}

export function loginFailureAction(loginError: string | undefined): LoginFailureAction {
  return {
    type: LOGIN_FAILURE,
    loginError,
  };
}

export function loginExpiredAction(): LoginExpiredAction {
  return {
    type: LOGIN_EXPIRED,
  };
}
