import React from 'react';
import BrowserWindow from '../BrowserWindow';
import { CampaignBuilderForm } from '../../reducers/campaignBuilder';

interface BuilderPreviewProps {
  formData: CampaignBuilderForm;
}

const BuilderPreview: React.FC<BuilderPreviewProps> = (props: BuilderPreviewProps) => (
  <BrowserWindow formData={props.formData} />
);

export default BuilderPreview;
