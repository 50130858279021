import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Login } from './Login';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { notMissing } from '@yieldify/gendry-dragonglass';
import Dashboard from './Dashboard';
import Header from './Header';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import { PrivateRoute, ProtectedRouteProps } from './PrivateRoute';
import Builder from './Builder';

interface AppProps {
  isAuthenticated: boolean;
  message?: string;
  previewToken?: string;
}

const App = ({ isAuthenticated, message, previewToken }: AppProps) => {
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated,
    authenticationPath: '/login',
  };
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route component={() => <Header previewToken={previewToken} />} />
        <Switch>
          {!isAuthenticated && (
            <Route path="/login" component={() => <Login message={message} />} />
          )}
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/builder/:notificationId"
            component={Builder}
          />
          <PrivateRoute {...defaultProtectedRouteProps} path="/dashboard" component={Dashboard} />
          <PrivateRoute {...defaultProtectedRouteProps} path="/" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ session }: AppState) => {
  const { userProfile, loginError, iid } = { ...session };
  return {
    isAuthenticated: notMissing(userProfile),
    message: loginError,
    previewToken: iid,
  };
};

export default connect(mapStateToProps)(App);
