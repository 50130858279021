import {
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  createCampaignAction,
} from '../actions/createCampaign';

interface CampaignState {
  campaignId: number | null;
  error: string | undefined;
  loading: boolean;
  linkUrl: string | undefined;
}

const initialState: CampaignState = {
  campaignId: null,
  error: undefined,
  loading: false,
  linkUrl: undefined,
};

export function createdCampaign(state = initialState, action: createCampaignAction): CampaignState {
  switch (action.type) {
    case CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignId: action.campaignId,
        linkUrl: action.linkUrl,
        loading: false,
        error: undefined,
      };
    case CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        campaignId: null,
        linkUrl: undefined,
      };
    default:
      return state;
  }
}
