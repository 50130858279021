export enum CustomErrorTypes {
  UNAUTHORISED = 'Unauthorised user',
  INVALID_CAMPAIGN = 'This campaign does not exist, or it has already been sent',
  ROUTE_NOT_FOUND = 'Route does not exist',
  DEFAULT_ERROR = 'There was an error with your API call',
}

export enum HttpResponseCodes {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORISED = 401,
  NOT_FOUND = 404,
}

class CustomError extends Error {
  public constructor(message: CustomErrorTypes) {
    super(message);
  }
}

export default CustomError;
