import { callJaime, ApiRequest } from '../util/api-utils';
import { config } from '../config';
import { History } from 'history';
import { APINotification } from '../reducers/notifications';
import { createCampaignOptions } from '../util/campaign-options';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';
import { CampaignBuilderForm } from '../reducers/campaignBuilder';
import { appendUtmParameters } from '../components/Builder/Form/Fieldsets/RedirectLinks';
import { parseUrl } from '@yieldify/gendry-dragonglass';

export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

export const DEFAULT_UTM_PARAMS = 'utm_medium=Push&utm_source=Yieldify';

export interface CreateCampaignPendingAction {
  type: typeof CREATE_CAMPAIGN_REQUEST;
}

export interface CreateCampaignSuccessAction {
  type: typeof CREATE_CAMPAIGN_SUCCESS;
  campaignId: number;
  linkUrl: string;
}

export interface CreateCampaignFailureAction {
  type: typeof CREATE_CAMPAIGN_FAILURE;
  error: string | undefined;
}

export type createCampaignAction =
  | CreateCampaignPendingAction
  | CreateCampaignSuccessAction
  | CreateCampaignFailureAction;

export function createCampaignPendingAction(): CreateCampaignPendingAction {
  return { type: CREATE_CAMPAIGN_REQUEST };
}

export const createCampaignSuccessAction = (
  history: History,
  notification: APINotification,
): CreateCampaignSuccessAction => {
  history.push(`/builder/${notification.id}`);
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    campaignId: notification.id,
    linkUrl: notification.linkUrl,
  };
};

export function createCampaignFailureAction(
  error: string | undefined,
): CreateCampaignFailureAction {
  return { type: CREATE_CAMPAIGN_FAILURE, error };
}

async function createCampaignHandleResponse(
  response: Response,
  authenticatedConfig: ApiRequest,
): Promise<APINotification> {
  const { id, linkUrl, version } = await response.json();
  // if this has what we want make another request to update
  // return id, linkUrl.
  const updateNotification = await updateLinkURLwithId(id, linkUrl, version, authenticatedConfig);
  return updateNotification.json();
}

export function createCampaign(
  history: History,
  initValues: Partial<CampaignBuilderForm> = {},
  // tslint:disable-next-line:no-any
): ThunkDispatch<void, AppState, Action<any>> {
  const options = createCampaignOptions(config.jaime, initValues);
  return callJaime(
    options,
    createCampaignPendingAction,
    (notification: APINotification) => createCampaignSuccessAction(history, notification),
    createCampaignFailureAction,
    (response: Response, authenticatedConfig: ApiRequest) =>
      createCampaignHandleResponse(response, authenticatedConfig),
  );
}

export const generateDefaultUtmParams = (campaignId: string) =>
  `${DEFAULT_UTM_PARAMS}&utm_campaign=yiel${campaignId}`;

async function updateLinkURLwithId(
  campaignId: string,
  linkUrl: string,
  version: number,
  authenticatedConfig: ApiRequest,
): Promise<Response> {
  const options: ApiRequest = {
    url: `${config.jaime}/notification/${campaignId}`,
    method: 'PUT',
    body: JSON.stringify({
      linkUrl: appendUtmParameters(parseUrl(linkUrl), generateDefaultUtmParams(campaignId)),
      version,
    }),
  };
  return fetch(options.url, {
    method: options.method,
    body: options.body,
    headers: {
      ...authenticatedConfig.headers,
    },
  });
}
