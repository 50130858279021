import { createJsonOptions, RequestMethodType, RequestHeaders } from './api-utils';
import { CampaignBuilderForm } from '../reducers/campaignBuilder';

interface CampaignOptions {
  url: string;
  body: string;
  method: RequestMethodType;
  headers?: RequestHeaders | undefined;
}

export function createCampaignOptions(
  baseUrl: string,
  initValues: Partial<CampaignBuilderForm> = {},
): CampaignOptions {
  return {
    url: baseUrl + '/notifications',
    ...createJsonOptions('POST'),
    body: JSON.stringify({
      name: 'Preview Demo',
      websiteId: 855,
      state: 'draft',
      topic: 'all',
      title: 'Your notification title',
      body: 'The body of your notification',
      iconUrl: 'https://yieldify-static-files.s3.amazonaws.com/web-push/yieldify-logo.png',
      linkUrl: 'https://www.yieldify.com',
      silent: false,
      ...initValues,
    }),
  };
}

export const deleteCampaignOptions = (baseUrl: string, campaignId: number, version: number) => ({
  url: baseUrl + '/notification/' + campaignId,
  ...createJsonOptions('DELETE', { version }),
});
