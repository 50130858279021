import { callJaime } from '../util/api-utils';
import { config } from '../config';
import { deleteCampaignOptions } from '../util/campaign-options';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../reducers';
import { Action } from 'redux';

export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE';

export interface DeleteCampaignPendingAction {
  type: typeof DELETE_CAMPAIGN_REQUEST;
}

export interface DeleteCampaignSuccessAction {
  type: typeof DELETE_CAMPAIGN_SUCCESS;
  id: number;
}

export interface DeleteCampaignFailureAction {
  type: typeof DELETE_CAMPAIGN_FAILURE;
  error: string | undefined;
}

export type DeleteCampaignAction =
  | DeleteCampaignPendingAction
  | DeleteCampaignSuccessAction
  | DeleteCampaignFailureAction;

export function deleteCampaignPendingAction(): DeleteCampaignPendingAction {
  return { type: DELETE_CAMPAIGN_REQUEST };
}

export function deleteCampaignSuccessAction(id: number): DeleteCampaignSuccessAction {
  return { type: DELETE_CAMPAIGN_SUCCESS, id };
}

export function deleteCampaignFailureAction(
  error: string | undefined,
): DeleteCampaignFailureAction {
  return { type: DELETE_CAMPAIGN_FAILURE, error };
}

export function deleteCampaignThunkAction(
  id: number,
  version: number,
  // tslint:disable-next-line:no-any
): ThunkDispatch<void, AppState, Action<any>> {
  const options = deleteCampaignOptions(config.jaime, id, version);
  return callJaime(
    options,
    deleteCampaignPendingAction,
    () => deleteCampaignSuccessAction(id),
    deleteCampaignFailureAction,
    () => undefined,
  );
}
