import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import './main.scss';
import { store } from './store/configureStore';
import {
  connectPersistence,
  localStoragePersist,
  STORAGE_KEY_USER_PROFILE,
  STORAGE_KEY_BUILDER_SETTINGS,
} from './store/persist';
import { AppState } from './reducers/index';
import Auth0Provider from './components/Auth0Provider';
import { config } from './config';

connectPersistence(
  store,
  (state: AppState) => state.session.userProfile,
  STORAGE_KEY_USER_PROFILE,
  localStoragePersist,
);

connectPersistence(
  store,
  (state: AppState) => state.campaignBuilder.settings,
  STORAGE_KEY_BUILDER_SETTINGS,
  localStoragePersist,
);

interface StoreWindow extends Window {
  store: typeof store;
}
declare const window: StoreWindow;
window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={config.auth0Config.domain}
      client_id={config.auth0Config.clientId}
      redirect_uri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
);
