import {
  CampaignBuilderActions,
  INPUT_CHANGE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  SAVE_NOTIFICATION_REQUEST,
  SAVE_NOTIFICATION_SUCCESS,
  SAVE_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
  SHOW_ERROR_MODAL,
  PREVIEW_NOTIFICATION_FAILURE,
  PREVIEW_NOTIFICATION,
  PREVIEW_NOTIFICATION_SUCCESS,
  CLIENT_SELECTION,
} from '../actions/campaignBuilder';
import { IID } from '../actions/web-push-client';
import { getPersistedStateFromStorage, STORAGE_KEY_BUILDER_SETTINGS } from '../store/persist';
import { ClientSettings } from '../containers/Builder';
import { combineReducers } from 'redux';
import { config } from '../config';
import { APINotification } from './notifications';

export interface CampaignBuilderForm {
  title: string;
  body: string;
  iconUrl: string;
  linkUrl: string;
  topic: string;
  name: string;
  websiteId: number;
  ycpCampaignId: number | null;
}

interface CampaignBuilder {
  campaignDetails: APINotification;
  clientList: ClientSettings[];
  errorHandling: {
    errorMessage: string | null;
    showErrorModal: boolean;
  };
  formData: CampaignBuilderForm;
  settings: BuilderSettings;
  pending: {
    gettingData: boolean;
    savingCampaign: boolean;
    sendingCampaign: boolean;
    previewingCampaign: boolean;
  };
  successHandling: {
    previewSuccess: boolean;
  };
}

export interface BuilderSettings {
  previewIID?: IID;
}

const blankFormData: CampaignBuilderForm = {
  title: '',
  body: '',
  iconUrl: '',
  linkUrl: '',
  topic: '',
  name: '',
  websiteId: 855,
  ycpCampaignId: null,
};

const DEFAULT_BUILDER_SETTINGS = {};

const initialState: CampaignBuilder = {
  settings: getPersistedStateFromStorage(STORAGE_KEY_BUILDER_SETTINGS) || DEFAULT_BUILDER_SETTINGS,
  campaignDetails: {
    silent: false,
    imageUrl: '',
    createdAt: '',
    updatedAt: '',
    sentAt: null,
    id: 1,
    version: 1,
    ...blankFormData,
  },
  clientList: config['client-whitelist'],
  errorHandling: {
    errorMessage: null,
    showErrorModal: false,
  },
  formData: blankFormData,
  pending: {
    gettingData: false,
    savingCampaign: false,
    sendingCampaign: false,
    previewingCampaign: false,
  },
  successHandling: {
    previewSuccess: false,
  },
};

function settings(state = initialState.settings, action: CampaignBuilderActions): BuilderSettings {
  switch (action.type) {
    case PREVIEW_NOTIFICATION:
      return {
        ...state,
        previewIID: action.iid,
      };
    default:
      return state;
  }
}

export const extractFormData = ({
  name,
  title,
  body,
  linkUrl,
  iconUrl,
  topic,
  websiteId,
  ycpCampaignId,
}: APINotification): CampaignBuilderForm => ({
  title,
  body,
  iconUrl,
  linkUrl,
  topic,
  name,
  websiteId,
  ycpCampaignId,
});

function formData(
  state = initialState.formData,
  action: CampaignBuilderActions,
): CampaignBuilderForm {
  switch (action.type) {
    case CLIENT_SELECTION:
      return {
        ...state,
        websiteId: action.selectedWebsiteId,
      };
    case INPUT_CHANGE:
      return {
        ...state,
        [action.inputName]: action.inputValue,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...extractFormData(action.response),
      };
    default:
      return state;
  }
}

function campaignDetails(
  state = initialState.campaignDetails,
  action: CampaignBuilderActions,
): CampaignBuilder['campaignDetails'] {
  switch (action.type) {
    case SAVE_NOTIFICATION_SUCCESS:
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...action.response,
      };
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sentAt: action.response.sentAt,
      };
    default:
      return state;
  }
}

function successHandling(
  state = initialState.successHandling,
  action: CampaignBuilderActions,
): CampaignBuilder['successHandling'] {
  switch (action.type) {
    case PREVIEW_NOTIFICATION:
      return {
        ...state,
        previewSuccess: false,
      };
    case PREVIEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        previewSuccess: true,
      };

    default:
      return state;
  }
}

function errorHandling(
  state = initialState.errorHandling,
  action: CampaignBuilderActions,
): CampaignBuilder['errorHandling'] {
  switch (action.type) {
    case GET_NOTIFICATION_FAILURE:
    case SAVE_NOTIFICATION_FAILURE:
    case SEND_NOTIFICATION_FAILURE:
    case PREVIEW_NOTIFICATION_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        showErrorModal: true,
      };
    case SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: !state.showErrorModal,
      };
    case PREVIEW_NOTIFICATION:
      return {
        ...state,
        showErrorModal: false,
        errorMessage: null,
      };
    default:
      return state;
  }
}

function pending(
  state = initialState.pending,
  action: CampaignBuilderActions,
): CampaignBuilder['pending'] {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        gettingData: true,
      };
    case SAVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        savingCampaign: true,
      };
    case PREVIEW_NOTIFICATION:
      return {
        ...state,
        previewingCampaign: true,
      };

    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        sendingCampaign: true,
      };

    // If we get a success or failure on a request, its associated pending status should be reset to false
    case SEND_NOTIFICATION_FAILURE:
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendingCampaign: false,
      };
    case PREVIEW_NOTIFICATION_FAILURE:
    case PREVIEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        previewingCampaign: false,
      };
    case GET_NOTIFICATION_FAILURE:
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        gettingData: false,
      };
    case SAVE_NOTIFICATION_FAILURE:
    case SAVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        savingCampaign: false,
      };
    default:
      return state;
  }
}

export const campaignBuilder = combineReducers({
  campaignDetails,
  formData,
  pending,
  settings,
  successHandling,
  errorHandling,
});
