import { notMissing } from '@yieldify/gendry-dragonglass';
import { ClientSettings } from './containers/Builder';

const {
  REACT_APP_JAIME_ENDPOINT,
  REACT_APP_VISERION_SIGN_IN_ENDPOINT,
  REACT_APP_MESSAGING_VAPID_PUBLIC_KEY,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
} = process.env;

const DEFAULT_VAPID_KEY =
  'BFZ4_47Jpq5u1R5bqTvQO5o5Aqnza_AZv0rtq2wPVpcEhx6aPYnbkN2Q05HCmcq8AEE7fzaMksewwNZPrWh6wWw';
const DEFAULT_SENDER_ID = '536527266952';

const CLIENT_WHITELIST: ClientSettings[] = [
  {
    organisationId: 307,
    websiteId: 852,
    name: 'YCP1 Prod Test',
    domain: 'ycp1.yieldifytraining.com',
  },
  { organisationId: 307, websiteId: 855, name: 'YCP4 Dev Test', domain: 'pushtest.stage1.xyz' },
  { organisationId: 100165, websiteId: 100303, name: 'Take Care Of', domain: 'takecareof.com' },
  {
    organisationId: 100136,
    websiteId: 100258,
    name: 'Caudalie ca-en',
    domain: 'ca-en.caudalie.com',
  },
  {
    organisationId: 100136,
    websiteId: 100259,
    name: 'Caudalie ca-fr',
    domain: 'ca-fr.caudalie.com',
  },
  { organisationId: 100048, websiteId: 100079, name: 'Mail Shop', domain: 'mailshop.co.uk' },
  { organisationId: 100054, websiteId: 100085, name: 'Mobilehelp', domain: 'mobilehelp.com' },
  { organisationId: 441, websiteId: 583, name: 'Soak UK', domain: 'soak.com' },
  { organisationId: 606, websiteId: 816, name: 'SpinLife', domain: 'spinlife.com' },
  {
    organisationId: 100046,
    websiteId: 100077,
    name: 'Bec + Bridge',
    domain: 'becandbridge.com.au',
  },
  { organisationId: 100121, websiteId: 100221, name: 'Bing Lee', domain: 'binglee.com.au' },
  {
    organisationId: 644,
    websiteId: 100197,
    name: 'Qantas Shopping',
    domain: 'shopping.qantas.com',
  },
  {
    organisationId: 747,
    websiteId: 1082,
    name: 'Newbalance AU',
    domain: 'https://www.newbalance.com.au/',
  },
  {
    organisationId: 100035,
    websiteId: 100062,
    name: 'Snap Rentals NZ',
    domain: 'https://www.snaprentals.co.nz/',
  },
  {
    organisationId: 100173,
    websiteId: 100312,
    name: 'Sticker You',
    domain: 'https://www.stickeryou.com/',
  },
  {
    organisationId: 100085,
    websiteId: 100149,
    name: 'Serenata Flowers',
    domain: 'https://www.serenataflowers.com/',
  },
  {
    organisationId: 322,
    websiteId: 343,
    name: 'High Street TV',
    domain: 'https://www.highstreettv.com/',
  },
  {
    organisationId: 100066,
    websiteId: 100104,
    name: 'Clarins SG staging site',
    domain:
      'https://staging-clarinschina-ecommera.demandware.net/on/demandware.store/Sites-clarinssg-Site',
  },
  {
    organisationId: 100066,
    websiteId: 100104,
    name: 'Clarins SG',
    domain: 'https://www.clarins.com.sg',
  },
  {
    organisationId: 752,
    websiteId: 1039,
    name: 'LOreal Kerastase',
    domain: 'https://www.kerastase.co.uk/',
  },
  {
    organisationId: 100110,
    websiteId: 100204,
    name: 'Healthy Chef',
    domain: 'https://thehealthychef.com/',
  },
  {
    organisationId: 100066,
    websiteId: 100105,
    name: 'Clarins AU',
    domain: 'https://www.clarins.com.au/',
  },
  {
    organisationId: 100265,
    websiteId: 100447,
    name: 'Jo Mercer AU',
    domain: 'https://www.jomercer.com.au/',
  },
  {
    organisationId: 100295,
    websiteId: 100490,
    name: 'LICKD',
    domain: 'https://lickd.co/',
  },
  {
    organisationId: 100264,
    websiteId: 100446,
    name: 'CheeseCake AU',
    domain: 'https://www.cheesecake.com.au/',
  },
  {
    organisationId: 100264,
    websiteId: 100448,
    name: 'CheeseCake NZ',
    domain: 'https://www.thecheesecakeshop.co.nz/',
  },
  {
    organisationId: 100179,
    websiteId: 100318,
    name: 'Kathy Kuo',
    domain: 'https://www.kathykuohome.com/',
  },
  {
    organisationId: 100125,
    websiteId: 100231,
    name: 'August Home',
    domain: 'https://august.com/',
  },
  {
    organisationId: 100303,
    websiteId: 100501,
    name: 'Fashion BNKR',
    domain: 'https://fashionbunker.com/',
  },
  {
    organisationId: 480,
    websiteId: 100211,
    name: 'spell designs usa',
    domain: 'https://usa.spell.co/',
  },
  {
    organisationId: 480,
    websiteId: 100341,
    name: 'spell designs aus',
    domain: 'https://aus.spell.co/',
  },
  {
    organisationId: 100104,
    websiteId: 100335,
    name: 'TS Training Site (Jordan)',
    domain: 'http://ts.yieldifytraining.com/',
  },
  {
    organisationId: 622,
    websiteId: 1081,
    name: 'City Chic AU',
    domain: 'https://www.citychic.com.au/',
  },
  {
    organisationId: 622,
    websiteId: 1080,
    name: 'City Chic NZ',
    domain: 'https://www.citychic.co.nz/',
  },
  {
    organisationId: 622,
    websiteId: 844,
    name: 'City Chic US',
    domain: 'https://www.citychiconline.com/',
  },
  {
    organisationId: 100356,
    websiteId: 100565,
    name: 'Marco Promos',
    domain: 'https://www.marcopromos.com/',
  },
  {
    organisationId: 100363,
    websiteId: 100574,
    name: 'JS Health AU',
    domain: 'http://jshealthvitamins.com/',
  },
  {
    organisationId: 445,
    websiteId: 587,
    name: 'Wine Selectors',
    domain: 'https://www.wineselectors.com.au/',
  },
  {
    organisationId: 100318,
    websiteId: 100520,
    name: 'Pai Skincare UK',
    domain: 'https://www.paiskincare.com/',
  },
  {
    organisationId: 100318,
    websiteId: 100519,
    name: 'Pai Skincare US',
    domain: 'https://www.paiskincare.us/',
  },
];

export const DEFAULT_PATH = '/dashboard';
export const LOGIN_PATH = '/login';

export const config = {
  'viserion-auth-endpoint': REACT_APP_VISERION_SIGN_IN_ENDPOINT!,
  jaime: REACT_APP_JAIME_ENDPOINT!,
  'client-whitelist': CLIENT_WHITELIST,
  messagingVapidPublicKey: notMissing(REACT_APP_MESSAGING_VAPID_PUBLIC_KEY)
    ? REACT_APP_MESSAGING_VAPID_PUBLIC_KEY
    : DEFAULT_VAPID_KEY,
  messagingSenderId: notMissing(REACT_APP_MESSAGING_SENDER_ID)
    ? REACT_APP_MESSAGING_SENDER_ID
    : DEFAULT_SENDER_ID,
  serviceWorkerPath: '/push-sw.js',
  previewRedirectTarget: 'https://www.yieldify.com',
  auth0Config: {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientId: REACT_APP_AUTH0_CLIENT_ID,
  },
};
