import React from 'react';
import SendButton from './Buttons/Send';
import EditNeededButton from './Buttons/EditNeeded';
import { CampaignBuilderThunkAction } from '../../actions/campaignBuilder';

interface Props {
  onClose: Function;
  isVisible: boolean;
  sendNotification: () => CampaignBuilderThunkAction;
}

const ConfirmationModal: React.FC<Props> = ({ isVisible, onClose, sendNotification }: Props) => {
  return (
    <div className={`confirmation-modal builder-modal-${isVisible ? 'visible' : 'hidden'}`}>
      <h3>Does everything look alright?</h3>
      <EditNeededButton clickHandler={onClose} />
      <SendButton
        clickHandler={() => {
          sendNotification();
          onClose();
        }}
      />
    </div>
  );
};

export default ConfirmationModal;
