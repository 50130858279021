import React from 'react';
import NotificationPreview from './NotificationPreview';
import { getClientDetails } from '../util/api-utils';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { CampaignBuilderForm } from '../reducers/campaignBuilder';

interface BrowserModelProps {
  formData: CampaignBuilderForm;
}

const BrowserModel: React.FC<BrowserModelProps> = ({ formData }: BrowserModelProps) => {
  const { linkUrl, websiteId } = formData;
  const client = getClientDetails(websiteId);
  let domain;
  if (notMissing(client)) {
    domain = client.domain;
  }
  return (
    <div className="browser-model">
      <div className="browser-pane">
        <svg width="100%" height="100%">
          <circle cx="24" cy="20" r="6" fill="#FF544C" />
          <circle cx="44" cy="20" r="6" fill="#FFB92A" />
          <circle cx="64" cy="20" r="6" fill="#25C837" />
        </svg>
      </div>
      <a
        href={linkUrl !== undefined && linkUrl !== '' ? linkUrl : `https://${domain}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <NotificationPreview notificationData={formData} />
      </a>
    </div>
  );
};

export default BrowserModel;
