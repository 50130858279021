import { LOGIN_SUCCESS, LoginAction, LOGIN_EXPIRED, LOGIN_FAILURE } from '../actions/users';
import { IID, WebPushClientAction, NEW_IID } from '../actions/web-push-client';
export interface Session {
  loginError?: string;
  userProfile?: UserProfile;
  initPath: string;
  iid?: IID;
}

export interface UserProfile {
  email?: string;
  token?: string;
}

const initialState: Session = {
  loginError: undefined,
  userProfile: undefined,
  initPath: window.location.pathname,
  iid: undefined,
};

export function session(state = initialState, action: LoginAction | WebPushClientAction): Session {
  switch (action.type) {
    case NEW_IID:
      return {
        ...state,
        iid: action.iid,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userProfile: {
          ...action.userProfile,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        userProfile: undefined,
        loginError: action.loginError,
      };
    case LOGIN_EXPIRED:
      return {
        ...state,
        userProfile: undefined,
      };
    default:
      return state;
  }
}
