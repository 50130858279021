import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../reducers/index';
import { builderInputChangeAction } from '../../../../actions/campaignBuilder';
import {
  notMissing,
  getQueryParameters,
  parseUrl,
  getOrigin,
  stringifyQueryParameters,
  Url,
  isMissing,
  stringifyUrl,
} from '@yieldify/gendry-dragonglass';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { extractFormData } from '../../../../reducers/campaignBuilder';

export const UTM_PARAM_REGEX = /utm_/i;
export const UTM_CAMPAIGN_PARAM = /utm_campaign=yiel=\d+/;

interface RedirectLinkProps {
  linkUrl: Url | null;
  utmParams: string | null;
  updateInputField: Function;
}

interface RedirectLinkState {
  inputText: string;
  disableUtm?: boolean;
  errorMessage?: string;
}

class RedirectLinkInput extends Component<RedirectLinkProps, RedirectLinkState> {
  public constructor(props: RedirectLinkProps) {
    super(props);
    this.state = {
      inputText: notMissing(props.linkUrl) ? stringifyUrl(props.linkUrl) : '',
      disableUtm: true,
    };
  }

  public componentWillReceiveProps = (
    nextProps: RedirectLinkProps,
    props: RedirectLinkProps,
  ): void => {
    if (nextProps.linkUrl !== props.linkUrl) {
      this.setState({
        inputText: notMissing(nextProps.linkUrl) ? stringifyUrl(nextProps.linkUrl) : '',
      });
    }
  };

  public render(): JSX.Element | null {
    const { linkUrl } = this.props;
    if (isMissing(linkUrl)) {
      return null;
    }
    return (
      <div className="redirect-link">
        <textarea
          required={true}
          id="redirect-link"
          name="redirect-link"
          autoComplete="redirect-link"
          onChange={this.handleChange}
          value={this.state.inputText}
          onBlur={() => this.updateUrl(this.state.inputText)}
          disabled={this.state.disableUtm}
        />
        {this.state.errorMessage && <p className="error">{this.state.errorMessage}</p>}
        <span className="lock" onClick={this.handleUnlockUTM}>
          {this.state.disableUtm ? <LockIcon /> : <LockOpenIcon />}
        </span>
      </div>
    );
  }

  private updateUrl = (inputStr: string) => {
    if (isUrlValid(inputStr)) {
      const url = this.buildUrl(parseUrl(inputStr));
      this.props.updateInputField('linkUrl', stringifyUrl(url));
    } else {
      this.setState({
        errorMessage: 'Invalid url',
      });
    }
  };

  private handleUnlockUTM = () => {
    this.setState(
      {
        disableUtm: !this.state.disableUtm,
        errorMessage: undefined,
      },
      () => {
        this.updateUrl(this.state.inputText);
      },
    );
  };

  private buildUrl = (url: Url): Url => {
    const { disableUtm } = this.state;
    if (disableUtm && notMissing(this.props.utmParams)) {
      return parseUrl(appendUtmParameters(url, this.props.utmParams));
    } else {
      return url;
    }
  };

  private handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputText = e.target.value;
    this.setState({
      inputText,
      errorMessage: undefined,
    });
  };
}

export const appendUtmParameters = (url: Url, utmParams: string): string => {
  const otherParams = getNonUtmParams(url);
  return `${getOrigin(url)}${url.path}?${utmParams}${
    otherParams.length > 0 ? `&${otherParams}` : ''
  }${notMissing(url.fragment) ? `#${url.fragment}` : ''}`;
};

export const urlStringHasCampaign = (url: string) => UTM_CAMPAIGN_PARAM.test(url);

const getNonUtmParams = (url: Url): string =>
  stringifyQueryParameters(
    getQueryParameters(url).filter((param) => !UTM_PARAM_REGEX.test(param.key)),
  );

const getUtmParams = (url: Url): string => {
  const queryParams = getQueryParameters(url);
  return stringifyQueryParameters(queryParams.filter((param) => UTM_PARAM_REGEX.test(param.key)));
};

const isUrlValid = (urlStr: string): boolean => {
  try {
    const url = parseUrl(urlStr);
    return notMissing(url);
  } catch (e) {
    return false;
  }
};

const mapStateToProps = ({ campaignBuilder }: AppState) => {
  const savedFormData = extractFormData(campaignBuilder.campaignDetails);
  const { formData } = campaignBuilder;
  const utmParams = isUrlValid(savedFormData.linkUrl)
    ? getUtmParams(parseUrl(savedFormData.linkUrl))
    : null;
  const linkUrl = isUrlValid(formData.linkUrl) ? parseUrl(formData.linkUrl) : null;
  return {
    linkUrl,
    utmParams,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateInputField: (name: string, inputValue: string | number) =>
      dispatch(builderInputChangeAction(name, inputValue)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectLinkInput);
