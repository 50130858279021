import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
import { config } from '../config';
import { callJaime, createJsonOptions } from '../util/api-utils';
import { IID } from './web-push-client';
import { CampaignBuilderForm } from '../reducers/campaignBuilder';
import { APINotification } from '../reducers/notifications';

export const CLIENT_SELECTION = 'CLIENT_SELECTION';
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const INPUT_CHANGE = 'BUILDER_INPUT_CHANGE';
export const READY_TO_PREVIEW_CLICK = 'BUILDER_PREVIEW_CLICK';
export const SAVE_NOTIFICATION_REQUEST = 'SAVE_NOTIFICATION_REQUEST';
export const SAVE_NOTIFICATION_SUCCESS = 'SAVE_NOTIFICATION_SUCCESS';
export const SAVE_NOTIFICATION_FAILURE = 'SAVE_NOTIFICATION_FAILURE';
export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';
export const PREVIEW_NOTIFICATION = 'BUILDER_PREVIEW_NOTIFICATION';
export const PREVIEW_NOTIFICATION_SUCCESS = 'BUILDER_PREVIEW_NOTIFICATION_SUCCESS';
export const PREVIEW_NOTIFICATION_FAILURE = 'BUILDER_PREVIEW_NOTIFICATION_FAILURE';
export const SHOW_ERROR_MODAL = 'BUILDER_SHOW_ERROR_MODAL';
export const STATUS_CHANGE = 'BUILDER_STATUS_CHANGE';

export interface CampaignBuilderClientSelectionAction {
  type: typeof CLIENT_SELECTION;
  selectedWebsiteId: number;
}

export interface CampaignBuilderShowErrorModalAction {
  type: typeof SHOW_ERROR_MODAL;
  showErrorModal: boolean;
}

export interface CampaignBuilderInputAction {
  type: typeof INPUT_CHANGE;
  inputName: string;
  inputValue: string | number;
}

export interface CampaignBuilderStatusChangeAction {
  type: typeof STATUS_CHANGE;
  sent: boolean;
}

export interface CampaignBuilderSaveNotificationAction {
  type: typeof SAVE_NOTIFICATION_REQUEST;
}

export interface CampaignBuilderSaveNotificationSuccessAction {
  type: typeof SAVE_NOTIFICATION_SUCCESS;
  response: APINotification;
}

export interface CampaignBuilderSaveNotificationFailureAction {
  type: typeof SAVE_NOTIFICATION_FAILURE;
  error: string;
}

export interface CampaignBuilderGetNotificationSuccessAction {
  type: typeof GET_NOTIFICATION_SUCCESS;
  response: APINotification;
}

export interface CampaignBuilderGetNotificationFailureAction {
  type: typeof GET_NOTIFICATION_FAILURE;
  error: string;
}

export interface CampaignBuilderGetNotificationAction {
  type: typeof GET_NOTIFICATION_REQUEST;
}

export interface CampaignBuilderSendNotificationAction {
  type: typeof SEND_NOTIFICATION_REQUEST;
}

export interface CampaignBuilderSendNotificationSuccess {
  type: typeof SEND_NOTIFICATION_SUCCESS;
  response: APINotification;
}

export interface CampaignBuilderSendNotificationFailure {
  type: typeof SEND_NOTIFICATION_FAILURE;
  error: string;
}

export interface CampaignBuilderPreviewNotificationSuccess {
  type: typeof PREVIEW_NOTIFICATION_SUCCESS;
  response: APINotification;
}

export interface CampaignBuilderPreviewNotificationFailure {
  type: typeof PREVIEW_NOTIFICATION_FAILURE;
  error: string;
}

export interface CampaignBuilderPreviewAction {
  type: typeof PREVIEW_NOTIFICATION;
  iid: IID;
}

export type CampaignBuilderThunkAction = ThunkAction<void, AppState, null, CampaignBuilderActions>;

export const builderPreviewAction = (iid: IID): CampaignBuilderPreviewAction => ({
  type: PREVIEW_NOTIFICATION,
  iid,
});

export const builderClientSelectionAction = (
  selectedWebsiteId: number,
): CampaignBuilderClientSelectionAction => ({
  type: CLIENT_SELECTION,
  selectedWebsiteId,
});

export const builderInputChangeAction = (
  inputName: string,
  inputValue: string | number,
): CampaignBuilderInputAction => ({
  type: INPUT_CHANGE,
  inputName,
  inputValue,
});

export const builderStatusChangeAction = (sent: boolean): CampaignBuilderStatusChangeAction => ({
  type: STATUS_CHANGE,
  sent,
});

export const builderShowErrorModalAction = (
  showErrorModal: boolean,
): CampaignBuilderShowErrorModalAction => ({
  type: SHOW_ERROR_MODAL,
  showErrorModal,
});

export const builderSendNotificationRequestAction = (): CampaignBuilderSendNotificationAction => ({
  type: SEND_NOTIFICATION_REQUEST,
});

export const builderSendNotificationSuccessAction = (
  response: APINotification,
): CampaignBuilderSendNotificationSuccess => ({
  type: SEND_NOTIFICATION_SUCCESS,
  response,
});

export const builderSendNotificationFailureAction = (
  error: string,
): CampaignBuilderSendNotificationFailure => ({
  type: SEND_NOTIFICATION_FAILURE,
  error,
});

export const builderPreviewNotificationSuccessAction = (
  response: APINotification,
): CampaignBuilderPreviewNotificationSuccess => ({
  type: PREVIEW_NOTIFICATION_SUCCESS,
  response,
});

export const builderPreviewNotificationFailureAction = (
  error: string,
): CampaignBuilderPreviewNotificationFailure => ({
  type: PREVIEW_NOTIFICATION_FAILURE,
  error,
});

export const builderGetNotificationRequestAction = (): CampaignBuilderGetNotificationAction => ({
  type: GET_NOTIFICATION_REQUEST,
});

export const builderGetNotificationFailureAction = (
  error: string,
): CampaignBuilderGetNotificationFailureAction => ({
  type: GET_NOTIFICATION_FAILURE,
  error,
});

export const builderGetNotificationSuccessAction = (
  response: APINotification,
): CampaignBuilderGetNotificationSuccessAction => ({
  type: GET_NOTIFICATION_SUCCESS,
  response,
});

export const builderSaveNotificationRequestAction = (): CampaignBuilderSaveNotificationAction => ({
  type: SAVE_NOTIFICATION_REQUEST,
});

export const builderSaveNotificationSuccessAction = (
  response: APINotification,
): CampaignBuilderSaveNotificationSuccessAction => ({
  type: SAVE_NOTIFICATION_SUCCESS,
  response,
});

export const builderSaveNotificationFailureAction = (
  error: string,
): CampaignBuilderSaveNotificationFailureAction => ({
  type: SAVE_NOTIFICATION_FAILURE,
  error,
});

export const builderSaveNotificationThunkAction = (
  id: string,
  version: number,
  newFormData: CampaignBuilderForm,
): Function => {
  const options = {
    url: config.jaime + '/notification/' + id,
    ...createJsonOptions('PUT', { ...newFormData, version }),
  };
  return callJaime(
    options,
    builderSaveNotificationRequestAction,
    builderSaveNotificationSuccessAction,
    builderSaveNotificationFailureAction,
    handleFetchResponse,
  );
};

async function handleFetchResponse(response: Response): Promise<APINotification> {
  return await response.json();
}

export const builderGetNotificationThunkAction = (id: string): Function => {
  const options = {
    url: config.jaime + '/notification/' + id,
    ...createJsonOptions('GET'),
  };
  return callJaime(
    options,
    builderGetNotificationRequestAction,
    builderGetNotificationSuccessAction,
    builderGetNotificationFailureAction,
    handleFetchResponse,
  );
};

export const builderSendNotificationThunkAction = (id: string, version: number): Function => {
  const options = {
    url: config.jaime + '/notifications/' + id + '/send',
    ...createJsonOptions('POST', { version }),
  };
  return callJaime(
    options,
    builderSendNotificationRequestAction,
    builderSendNotificationSuccessAction,
    builderSendNotificationFailureAction,
    handleFetchResponse,
  );
};

export const previewNotificationThunkAction = (id: string, token: IID, version: number) => {
  const options = {
    url: `${config.jaime}/notifications/${id}/preview/${token}`,
    ...createJsonOptions('POST', { version }),
  };

  return callJaime(
    options,
    () => builderPreviewAction(token),
    builderPreviewNotificationSuccessAction,
    builderPreviewNotificationFailureAction,
    handleFetchResponse,
  );
};

export type CampaignBuilderActions =
  | CampaignBuilderInputAction
  | CampaignBuilderStatusChangeAction
  | CampaignBuilderGetNotificationAction
  | CampaignBuilderGetNotificationFailureAction
  | CampaignBuilderGetNotificationSuccessAction
  | CampaignBuilderSaveNotificationAction
  | CampaignBuilderSaveNotificationFailureAction
  | CampaignBuilderSaveNotificationSuccessAction
  | CampaignBuilderSendNotificationAction
  | CampaignBuilderSendNotificationSuccess
  | CampaignBuilderSendNotificationFailure
  | CampaignBuilderPreviewAction
  | CampaignBuilderPreviewNotificationSuccess
  | CampaignBuilderPreviewNotificationFailure
  | CampaignBuilderShowErrorModalAction
  | CampaignBuilderShowErrorModalAction
  | CampaignBuilderClientSelectionAction;
