import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

interface OwnProps {
  disabled?: boolean;
  clickHandler: Function;
  className?: string;
}

const DeleteButton = ({ disabled = false, className, clickHandler }: OwnProps) => {
  return (
    <IconButton
      color="default"
      onClick={(e) => {
        e.preventDefault();
        clickHandler();
      }}
      title="Delete"
      className={className}
      disabled={disabled}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteButton;
