import moment from 'moment';

export function formatDatetime(dateTimeString: string): string {
  // Example: 2019-12-09T15:12:14.000Z
  const dateTime = moment(dateTimeString);
  if (dateTime.isValid()) {
    const formattedDateTime = dateTime.format('DD/MM/YYYY HH:MM:SS');
    return formattedDateTime;
  }
  return dateTimeString;
}
