import {
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  DeleteCampaignAction,
} from '../actions/deleteCampaign';

interface DeleteCampaign {
  error: string | undefined;
  loading: boolean;
}

const initialState: DeleteCampaign = {
  error: undefined,
  loading: false,
};

export function deleteCampaign(state = initialState, action: DeleteCampaignAction): DeleteCampaign {
  switch (action.type) {
    case DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
