import React from 'react';
import { useAuth0 } from '../components/Auth0Provider';
import { Button } from '@material-ui/core';

interface LoginProps {
  message?: string;
}
export const Login = ({ message }: LoginProps) => {
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();

  const handleLogin = () => loginWithRedirect();
  const handleLogout = () => logout();
  return (
    <div className="login">
      <div>
        {!message ? (
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
            className="submit"
            onClick={handleLogin}
          >
            Login Through Drogon
          </Button>
        ) : (
          <div>
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              className="submit"
              onClick={handleLogout}
            >
              Logout Of Drogon
            </Button>
            <p>{message}</p>
            <h2>You will need to logout and log back in with an authorised account</h2>
          </div>
        )}
      </div>
    </div>
  );
};
