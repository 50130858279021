import { config } from '../config';
import { callJaime, getClientDetails, createJsonOptions } from '../util/api-utils';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { PushNotification, APINotification } from '../reducers/notifications';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export type NotificationsAction =
  | NotificationsSuccessAction
  | NotificationsErrorAction
  | NotificationsPendingAction;

interface NotificationsPendingAction {
  type: typeof NOTIFICATIONS_REQUEST;
}
export interface NotificationsSuccessAction {
  type: typeof NOTIFICATIONS_SUCCESS;
  items: PushNotification[];
}

export interface NotificationsErrorAction {
  type: typeof NOTIFICATIONS_FAILURE;
  error: string | null;
}

function notificationsPendingAction(): NotificationsPendingAction {
  return {
    type: NOTIFICATIONS_REQUEST,
  };
}

function notificationsSuccessAction(notifications: PushNotification[]): NotificationsSuccessAction {
  return {
    type: NOTIFICATIONS_SUCCESS,
    items: notifications,
  };
}

function notificationsErrorAction(error: string | null): NotificationsErrorAction {
  return {
    type: NOTIFICATIONS_FAILURE,
    error,
  };
}

function mergeNotificationWithClient(notifications: APINotification[]): PushNotification[] {
  const items: PushNotification[] = [];
  notifications.forEach((item: APINotification) => {
    const client = getClientDetails(item.websiteId);
    if (notMissing(client)) {
      items.push({
        ...item,
        clientName: client.name,
        domain: client.domain,
      });
    }
  });
  if (items.length < notifications.length && items === []) {
    throw new Error('You have created notifications for clients that are not on the whitelist');
  }
  return items;
}

async function handleNotificationsResponse(response: Response): Promise<PushNotification[]> {
  const json = await response.json();
  return mergeNotificationWithClient(json);
}

export function getNotificationsAction(): Function {
  const options = {
    url: config.jaime + '/notifications',
    ...createJsonOptions('GET'),
  };
  return callJaime(
    options,
    notificationsPendingAction,
    notificationsSuccessAction,
    notificationsErrorAction,
    handleNotificationsResponse,
  );
}
