import React from 'react';
import YieldifyLogoWhite from '../assets/yieldify_logo_white.png';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import FirebaseIIDWrapper from './FirebaseIIDWrapper';
import { Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  // tslint:disable:no-magic-numbers
  MuiAppBar: {
    backgroundColor: '#07c',
    padding: '10px 0',
    margin: '0 auto',
  },
  MuiToolBar: {
    padding: '0',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    paddingLeft: 'calc(2% + 20px)', // the 20px is to align the 'Y' in Yieldify
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  iid: {
    maxWidth: '210px',
    paddingRight: '4%',
    [theme.breakpoints.down(500)]: {
      maxWidth: '110px',
    },
  },
  logo: {
    maxHeight: '58px',
    margin: '16px 0',
    [theme.breakpoints.down(1000)]: {
      maxHeight: '48px',
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '38px',
    },
  },
  // tslint:enable:no-magic-numbers
}));

interface HeaderProps {
  previewToken: string | undefined;
}

const Header = ({ previewToken }: HeaderProps) => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.MuiAppBar}>
      <Toolbar className={classes.MuiToolBar}>
        <Link className={classes.logoContainer} to="/dashboard">
          <img className={classes.logo} alt="Yieldify Logo" src={YieldifyLogoWhite} />
        </Link>
        <FirebaseIIDWrapper className={classes.iid} />
        {previewToken && (
          <div className="email-token">
            <a href={`mailto:techteam@yieldify.com?subject=deviceToken=${previewToken}`}>
              <Button startIcon={<EmailIcon />} color="secondary" className={classes.menuButton}>
                Email Preview Token
              </Button>
            </a>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
