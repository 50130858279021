import { isMissing } from '@yieldify/gendry-dragonglass';
import * as firebase from 'firebase';

export type MessagingClient = firebase.messaging.Messaging;

let messagingClient: MessagingClient | null = null;
let firebaseSdk: firebase.app.App | null = null;

export const getFirebaseSdk = (options: Object) => {
  if (isMissing(firebaseSdk)) {
    firebaseSdk = firebase.initializeApp(options);
  }
  return firebaseSdk;
};

export const getFirebaseMessagingSdk = (vapidPublicKey: string, senderId: string) => {
  // This means we will only ever initialise the client once since we store its instance globally
  if (isMissing(messagingClient)) {
    // For firebase, the senderId should come from the firebase console, eg: https://console.firebase.google.com/u/0/project/web-push-24df5/settings/cloudmessaging/
    const sdk = getFirebaseSdk({ messagingSenderId: senderId });
    messagingClient = sdk.messaging();
    // The web push protocol uses VAPID authentication (https://developers.google.com/web/updates/2016/07/web-push-interop-wins)
    // This is the public key associated with the private key that will sign the push notification messages
    // This public key is also retrieved from the firebase console
    messagingClient.usePublicVapidKey(vapidPublicKey);
  }
  return messagingClient;
};

export const isMessagingSupported = () => firebase.messaging.isSupported();
