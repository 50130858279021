import React from 'react';
import UIFormFieldset from './Fieldsets/UIForm';
import AnalyticsAndReportingFieldset from './Fieldsets/AnalyticsAndReporting';
import SaveButton from '../Buttons/Save';
import BuilderClientDropdown from '../../../containers/Builder/ClientDropdown';
import { ClientSettings } from '../../../containers/Builder';
import { getClientDetails } from '../../../util/api-utils';
import { CampaignBuilderForm } from '../../../reducers/campaignBuilder';

interface FormProps {
  clientList: ClientSettings[];
  hasFormUpdated: boolean;
  saveButtonFn: Function;
  selectedClientId: number;
  showButton: boolean;
  formData: CampaignBuilderForm;
}

const handleSubmit = (e: React.FormEvent, props: FormProps) => {
  e.preventDefault();
  props.saveButtonFn();
};

const BuilderForm: React.FC<FormProps> = (props: FormProps) => {
  const { clientList, formData, hasFormUpdated, selectedClientId, showButton } = props;
  return (
    <form
      className="push-notification-form"
      autoComplete="off"
      onSubmit={(e) => handleSubmit(e, props)}
    >
      <BuilderClientDropdown
        clientList={clientList}
        selectedClient={getClientDetails(selectedClientId)}
      />
      <UIFormFieldset />
      <AnalyticsAndReportingFieldset
        selectedClient={getClientDetails(selectedClientId)}
        ycpCampaignId={formData.ycpCampaignId}
      />
      {showButton && <SaveButton hasFormUpdated={hasFormUpdated} />}
    </form>
  );
};

export default BuilderForm;
