import React, { version } from 'react';
import { ButtonBase, Card, CardHeader, CardContent } from '@material-ui/core';
import NotificationPreview from '../NotificationPreview';
import { extractFormData } from '../../reducers/campaignBuilder';
import { PushNotification } from '../../reducers/notifications';
import { notMissing } from '@yieldify/gendry-dragonglass';
import { colourCode } from '../../util/colour-palette';
import DuplicateCampaignButton from '../Dashboard/Buttons/DuplicateCampaign';
import DeleteCampaignButton from '../Dashboard/Buttons/DeleteCampaign';
import { formatDatetime } from '../../util/datetime-formatting';

interface OwnProps {
  notification: PushNotification;
  deleteCampaign: Function;
  duplicateCampaign: Function;
}

const { yellow, darkGrey } = colourCode;

const CampaignCard = ({ notification, deleteCampaign, duplicateCampaign }: OwnProps) => {
  const { id, sentAt, topic, name } = notification;
  const sent = notMissing(sentAt);
  return (
    <ButtonBase style={{ backgroundColor: sent ? darkGrey : yellow }} href={`/builder/${id}`}>
      <Card>
        <CardHeader
          disableTypography={true}
          title={name}
          action={
            <div>
              {sent ? null : (
                <DeleteCampaignButton
                  className="delete-campaign-fab"
                  clickHandler={() => deleteCampaign(id, version)}
                />
              )}
              <DuplicateCampaignButton
                className="duplicate-campaign-fab"
                clickHandler={() => duplicateCampaign(notification)}
                disabled={sent}
              />
            </div>
          }
        />
        <CardContent>
          <div className="notification-preview">
            <NotificationPreview notificationData={extractFormData(notification)} />
          </div>

          <div className="campaign-details">
            <p>
              <b>Topic:</b> {topic}
            </p>
            <p>
              <b>Status: </b>
              {sent ? 'Sent at ' + formatDatetime(sentAt!) : 'Draft'}
            </p>
          </div>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default CampaignCard;
