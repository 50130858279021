import React, { useState, ChangeEvent } from 'react';
import { Modal, Paper, TextField, makeStyles, Button, Fade, IconButton } from '@material-ui/core';
import FirebaseIIDWrapper from '../../../containers/FirebaseIIDWrapper';
import classnames from 'classnames';
import SendIcon from '@material-ui/icons/Send';
import { config } from '../../../config';
import { IID } from '../../../actions/web-push-client';
import CheckIcon from '@material-ui/icons/Check';
import CloseRounded from '@material-ui/icons/CloseRounded';
import {
  CampaignBuilderThunkAction,
  previewNotificationThunkAction,
} from '../../../actions/campaignBuilder';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';
import { usePrevious } from '../../../util/hooks';

interface OwnProps {
  disabled: boolean;
  notificationId: string;
  version: number;
}

interface StateProps {
  lastPreviewIID?: IID;
  deviceIID?: IID;
  previewPending: boolean;
  previewSuccess: boolean;
}

interface DispatchProps {
  previewNotification: (id: string, token: IID, version: number) => CampaignBuilderThunkAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const useStyles = makeStyles((theme) => ({
  // tslint:disable:no-magic-numbers
  modalWrapper: {
    margin: 'auto',
  },
  iid: {
    maxWidth: '90%',
    width: '250px',
    overflow: 'hidden',
  },
  modalContent: {
    maxWidth: '90vw',
    width: '500px',
    padding: '1em 2em',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%',
  },
  button: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  sendIcon: {
    marginRight: theme.spacing(1),
  },
  successIcon: {
    marginLeft: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
  },
  // tslint:enable:no-magic-numbers
}));

const PreviewButton = ({
  previewNotification,
  disabled,
  deviceIID,
  lastPreviewIID,
  notificationId,
  version,
  previewPending,
  previewSuccess,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [previewToken, setPreviewToken] = useState<string | undefined>(lastPreviewIID);
  const [success, setSuccess] = useState(false);
  const previousPreviewPending = usePrevious(previewPending);

  const handleClose = () => setOpen(false);

  // We need to use this local success state
  // The previewSuccess state variable is only reset when a new preview request occurs
  // So this checks that the preview pending state just switched to true and the previewSuccess is true
  if (previousPreviewPending && !previewPending && previewSuccess && !success) {
    setSuccess(true);
  }

  return (
    <div>
      <button
        type="submit"
        className="button preview-button"
        onClick={() => {
          setOpen(true);
          setSuccess(false);
        }}
        disabled={disabled}
      >
        Preview
      </button>
      <Modal
        className={classes.modalWrapper}
        aria-labelledby="Preview Push Notification"
        aria-describedby="Modal with IID input to preview notification on a specific device"
        open={open}
        onClose={handleClose}
      >
        <Paper className={classnames('modal center', classes.modalContent)}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseRounded />
          </IconButton>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setSuccess(false);
              previewNotification(notificationId, previewToken!, version);

              // If the IID submitted is the current device's IID
              // Redirect to another domain otherwise the push notification won't be displayed
              if (deviceIID === previewToken) {
                window.open(config.previewRedirectTarget, '_blank');
              }
            }}
          >
            <h2 id="preview-modal-title">Preview Web Push Notification</h2>
            <p id="simple-modal-description">Enter a device's IID (preview token)</p>
            <FirebaseIIDWrapper className={classes.iid} />
            <TextField
              id="preview-modal-iid-input"
              label="IID (Preview Token)"
              className={classes.textField}
              type="text"
              required={true}
              autoComplete="iid"
              margin="normal"
              value={previewToken}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPreviewToken(e.target.value);
                setSuccess(false);
              }}
              helperText={
                previewToken === deviceIID || previewToken === ''
                  ? ''
                  : 'Your device IID differs from your preview token'
              }
            />
            <div>
              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                <SendIcon className={classes.sendIcon} />
                <span>Preview!</span>
                {success && (
                  <Fade in={success}>
                    <CheckIcon className={classes.successIcon} />
                  </Fade>
                )}
              </Button>
            </div>
          </form>
        </Paper>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ campaignBuilder, session }: AppState) => ({
  lastPreviewIID: campaignBuilder.settings.previewIID,
  previewPending: campaignBuilder.pending.previewingCampaign,
  previewSuccess: campaignBuilder.successHandling.previewSuccess,
  deviceIID: session.iid,
});

const mapDispatchToProps = (dispatch: Function) => ({
  previewNotification: (id: string, token: IID, version: number) =>
    dispatch(previewNotificationThunkAction(id, token, version)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewButton);
