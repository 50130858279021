import React from 'react';
import { connect } from 'react-redux';
import {
  builderInputChangeAction,
  CampaignBuilderInputAction,
} from '../../actions/campaignBuilder';
import { Dispatch } from 'redux';
import { AppState } from '../../reducers';
import { CampaignBuilderForm } from '../../reducers/campaignBuilder';
import { notMissing } from '@yieldify/gendry-dragonglass';

interface OwnProps {
  name: keyof CampaignBuilderForm;
  placeholder: string;
  required?: boolean;
  type?: string;
}

interface StateProps {
  currentValue: string | number;
  disabled: boolean;
}

interface DispatchProps {
  dispatchInputUpdateAction: (name: string, input: string | number) => CampaignBuilderInputAction;
}

type Props = OwnProps & StateProps & DispatchProps;

const BuilderTextInput: React.FC<Props> = ({
  currentValue,
  disabled = false,
  dispatchInputUpdateAction,
  name,
  placeholder,
  required = true,
  type = 'text',
}: Props) => {
  return (
    <input
      value={notMissing(currentValue) ? currentValue : ''}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={(event) => dispatchInputUpdateAction(name, event.target.value)}
      required={required}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => ({
  currentValue: state.campaignBuilder.formData[ownProps.name] as string | number,
  disabled: notMissing(state.campaignBuilder.campaignDetails.sentAt),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchInputUpdateAction: (name: string, inputValue: string | number) =>
    dispatch(builderInputChangeAction(name, inputValue)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(BuilderTextInput);
