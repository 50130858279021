import React from 'react';

interface OwnProps {
  disabled?: boolean;
  clickHandler: Function;
}

type Props = OwnProps;

const EditNeededButton = ({ disabled = false, clickHandler }: Props) => {
  return (
    <button
      type="submit"
      className="button edit-needed-button"
      onClick={() => clickHandler()}
      disabled={disabled}
    >
      No - not quite right
    </button>
  );
};

export default EditNeededButton;
